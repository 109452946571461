/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-tag-spacing */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { returnScheduleContext } from '../../ScheduleContext'
import openCloseIcon from '../../../../assets/images/close-icon.png'
import dropdownArrow from '../../../../assets/images/drop6.svg'
import './dropdown.scss'

const Dates = () => {
  const { t } = useTranslation('common')
  const { fetchEPGByDate } = returnScheduleContext()
  const [dates, setDates] = useState([])
  const [focused, setFocused] = useState(1)
  const [isDropdownActive, setIsDropDownActive] = useState(false)
  const formatDate = (index, date) => {
    let strActualDate
    if (date) {
      strActualDate = date.toLocaleDateString('en-US', {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
      })
    }
    switch (index) {
      case 0:
        return t('common.yesterday')
      case 1:
        return t('common.today')
      case 2:
        return t('common.tomorrow')
      default: {
        const [weekday, shortDate] = strActualDate.split(',')
        return `${t(`common.${weekday}`)}${shortDate}`
      }
    }
  }
  const [dropdownText, setDropdownText] = useState(formatDate(1, undefined)) // DEFAULT TEXT TO BE SHOWN

  useEffect(() => {
    const newDates = []
    const todaysDate = new Date()
    newDates.push(new Date(todaysDate.setDate(todaysDate.getDate() - 1))) // Yesterday

    for (let i = 0; i < 5; i += 1) {
      newDates.push(new Date(todaysDate.setDate(todaysDate.getDate() + 1))) // Today and 4 days more
    }
    setDates(newDates)
  }, [])
  return (
    <div className="dates">
      <div className="responsive-dropdown">
        <div className="dropdownOption">
          <button
            type="button"
            icon={dropdownArrow}
            className=" focus dropdownBtn"
            onClick={() => setIsDropDownActive(true)}
          >
            <span>{ dropdownText }</span>
            <img src={dropdownArrow} alt="Arrow"/>
          </button>
        </div>
        {
          (isDropdownActive)
            ? (
              <div className="dropdown-overlay">
                <div className="dropdown-overlay-content">
                  {dates.map((item, index) => (
                    <button
                      key={item.toLocaleDateString()}
                      type="button"
                      onClick={() => {
                        setFocused(index)
                        fetchEPGByDate(item, true)
                        setDropdownText(formatDate(index, item))
                        setIsDropDownActive(false)
                      }}
                    >
                      <span className={(index === focused) ? 'focus-drop' : 'unFocus-drop'} >
                        { formatDate(index, item) }
                      </span>
                    </button>
                  ))}
                  <div className="open-icon-container">
                    <button
                      className="open-icon"
                      type="button"
                      onClick={() => setIsDropDownActive(false)}
                    >
                      <img src={openCloseIcon} alt="menu icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : ''
        }
      </div>
      {
        dates.map((item, index) => (
          <div className="button-container" key={item}>
            <div className="dates-container">
              <button
                type="button"
                className={(index === focused) ? 'focus' : 'unFocus'}
                key={item.toLocaleDateString()}
                onClick={() => {
                  setFocused(index)
                  fetchEPGByDate(item, true)
                }}
              >
                { formatDate(index, item) }
              </button>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default Dates
