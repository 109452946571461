/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable import/prefer-default-export */
import React, {
  createContext, useContext, useState,
} from 'react'
import { useLocation } from 'react-router-dom'

const ProgramContext = createContext({
  programInfo: {},
  channelInPlay: '',
  programVideo: '',
  fetchCurrentProgram: () => { },
})

// eslint-disable-next-line react/prop-types
export const FetchProgramContext = ({ children }) => {
  const [programInfo, setProgramInfo] = useState()
  const [upNext, setUpNext] = useState('')
  const [programInPlay, setActualProgramInPlay] = useState({
    id: '',
    programVideo: '',
    channel: '',
  })
  const [showProgramInformation, setShowProgramInformation] = useState(false)
  const location = useLocation()
  const [alreadyPlayed, setAlreadyPlayed] = useState([])
  const [alreadyUnmuted, setAlreadyUnmuted] = useState(false)

  // Fetch to get the manifest url of the program
  const fetchCurrentProgram = (program, upNextProgram) => {
    setUpNext(upNextProgram)
    setProgramInfo({ ...program })
    setActualProgramInPlay({
      id: program.id,
      programVideo: program.url,
      channel: program.channel,
      currentMinute: program.currentMinute,
    })

    if (location.pathname.includes('schedule')) {
      setTimeout(() => {
        setShowProgramInformation(true)
      }, 100)
    }
  }

  const playedAd = (programID) => {
    setAlreadyPlayed([...alreadyPlayed, programID])
  }

  // Fetch to get the program information
  const fetchInformationProgram = (program) => {
    setProgramInfo({ ...program })
    setTimeout(() => {
      setShowProgramInformation(true)
    }, 100)
  }

  return (
    <ProgramContext.Provider value={{
      programInfo,
      fetchCurrentProgram,
      fetchInformationProgram,
      upNext,
      programInPlay,
      showProgramInformation,
      setShowProgramInformation,
      alreadyPlayed,
      playedAd,
      alreadyUnmuted,
      setAlreadyUnmuted,
    }}
    >
      {children}
    </ProgramContext.Provider>
  )
}

export const returnProgramContext = () => {
  const context = useContext(ProgramContext)
  if (!context) return 'There is not context'
  return context
}
