/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Program from './Program'
import { returnScheduleContext } from '../../ScheduleContext'
import Hours from '../Hours/Hours'
import useScreen from '../../../utils/Observer'
import { returnProgramContext } from '../../ProgramContext'
import guideIconSmall from '../../../../assets/images/guideIconSmall.svg'
import watchNow from '../../../../assets/images/watchNowBtn.svg'
import error404 from '../../../../assets/images/error404.svg'

// eslint-disable-next-line object-curly-newline
const Programs = ({ setRef, channelsRef, handleFullScreen, isPipModeActive, isSkeletonActive }) => {
  const completeSchedule = React.createRef()
  const { t } = useTranslation('common')
  const timeline = React.createRef()
  const observer = useScreen(timeline, 1)

  const [userInteraction, setUserInteraction] = useState(false)

  const {
    scheduleJson, todaysDate, actualMinute, userIsOnToday,
    selectedDate, channelHeight, minuteInPixels, hoursHeight,
    quantToShow, scrollWidth,
  } = returnScheduleContext()
  const { setShowProgramInformation } = returnProgramContext()
  const minutesInDay = 1440

  let firstTime = true

  /**
   * Observer for the timeline:
   *  observer true if user is watching the timeline
   *  observer false if user is not watching the timeline
   * */
  useEffect(() => {
    if (observer) {
      setUserInteraction(false)
    } else {
      setUserInteraction(true)
    }
  }, [observer])

  /**
   * First page render -> scroll to actual program position
   */
  const scrollRef = () => {
    if (actualMinute >= 1260) {
      // Move scroll to end of actual minute after 9:00PM
      completeSchedule.current.scrollLeft = actualMinute * minuteInPixels
    } else if (actualMinute >= 180) {
      // Move scroll to actual minute after 3:00AM
      completeSchedule.current.scrollLeft = (actualMinute * minuteInPixels)
      - (completeSchedule.current.clientWidth / 2)
    } else {
      // First 3 hours are showed without scrolling
      completeSchedule.current.scrollLeft = 0
    }
  }

  /**
 *  Move to actual time
 */
  const goNow = () => {
    setUserInteraction(false)
    scrollRef()
  }

  // Flag used to only color one program by channel
  const resetFlag = () => {
    firstTime = true
  }

  // Get if program has progress
  const getProgramColor = (programInfo) => {
    if (firstTime && new Date(programInfo.end) >= todaysDate) {
      firstTime = false
      const percentage = (new Date(programInfo.end) - todaysDate) / 60000

      return {
        isActualProgram: true,
        actualPercentage: percentage,
      }
    }
    return {
      isActualProgram: false,
    }
  }

  useEffect(() => {
    if (completeSchedule.current && userIsOnToday) {
      setRef(completeSchedule.current)
      // On first render, scroll to actual timeline position
      goNow()
    } else {
      completeSchedule.current.scrollLeft = 0
    }
  }, [scheduleJson, userIsOnToday])

  // Every minute
  useEffect(() => {
    // Update timeline every minute
    resetFlag()
    // Update the scrollbar when the current time changes and user is not interacting
    if (!userInteraction && userIsOnToday) scrollRef()
  }, [actualMinute])

  /**
   * Move channels with the actual program scrolled on user view
   * @param {} value get scroll movement event
   */
  const handleScroll = (value) => {
    setShowProgramInformation(false)

    channelsRef.scroll({
      top: value.target.scrollTop,
    })
  }

  return (
    <div className="right-column">
      <div className="programs" ref={completeSchedule} style={{ width: `calc(100vw - ${channelHeight}px)` }}>
        {
          userIsOnToday
            ? <div className="time-line" ref={timeline} style={{ left: `${(actualMinute * minuteInPixels) - (scrollWidth / 2)}px`, top: hoursHeight - 5 }} />
            : ''
        }

        <Hours isSkeletonActive={isSkeletonActive} minuteInPixels={minuteInPixels} hoursHeight={hoursHeight} />
        <div className="programs-list" style={{ height: (channelHeight * quantToShow), width: `${scheduleJson?.length === 0 ? '100%' : 'fit-content'}` }} onScroll={handleScroll}>
          { selectedDate && scheduleJson?.map((channel, channelIndex) => (
            <div className="programs-container" style={{ width: (minutesInDay * minuteInPixels), maxWidth: (minutesInDay * minuteInPixels) }} key={channel.id + channel.name}>
              <div className="programs-horizontal">
                {resetFlag()}
                { channel.programs && channel.programs.map((element, index, schedules) => (
                  <Program
                    key={element.id}
                    isFirst={channelIndex === 0}
                    channelHeight={channelHeight}
                    program={{ ...element, channel: channel.id }}
                    actualProgram={getProgramColor(element)}
                    selectedDate={selectedDate}
                    upNextProgram={schedules[index + 1] ? schedules[index + 1] : null}
                    minuteInPixels={minuteInPixels}
                  />
                ))}
              </div>
            </div>
          ))}
          {scheduleJson?.length === 0
            ? (
              <div className="error">
                <img alt="error404" src={error404} />
                <h1>{t('common.oops')}</h1>
              </div>
            ) : ''}
        </div>
      </div>
      <div className="EPG-buttons-container">
        {
        userInteraction && userIsOnToday
          ? (
            <button className="watch-now" onClick={() => goNow()} type="button">
              <img src={watchNow} alt="watch now" />
            </button>
          )
          : ''
        }
        {
          !isPipModeActive // HIDES EPG GUIDE BUTTON WHEN PIP MODE IS ACTIVE
            ? (
              <button className="guide-button" onClick={() => handleFullScreen()} type="button">
                <p>{t('common.guide')}</p>
                <img src={guideIconSmall} alt="watch now" />
              </button>
            )
            : ''
        }
      </div>
    </div>
  )
}

Programs.propTypes = {
  setRef: PropTypes.func,
  channelsRef: PropTypes.instanceOf(Element),
  handleFullScreen: PropTypes.func,
  isPipModeActive: PropTypes.bool,
  isSkeletonActive: PropTypes.bool,
}

Programs.defaultProps = {
  setRef: () => {},
  channelsRef: document.getElementById('channels-list'),
  handleFullScreen: () => {},
  isPipModeActive: false,
  isSkeletonActive: false,
}

export default Programs
