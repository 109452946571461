import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Button from '../button/Button'
import tabsJson from '../../../../assets/json/tabs.json'
import './SmallScreenNavBar.scss'

const Navbar = () => {
  const [tabs, setTabs] = useState([])
  const { t } = useTranslation('common')
  const showNavbar = useSelector((state) => state.user.showNavbar)

  useEffect(() => {
    setTabs(tabsJson)
  }, [])

  return (
    showNavbar
    && (
    <div className="navbar-responsive">
        {
            tabs.map((tabSection) => (
              tabSection.tabs?.length > 0 ? (
                <div className="responsive-menu-nav" style={{ height: 'inherit' }} key={tabSection.title}>
                  <section className="navbarButton tabs-section-res" style={{ height: 'inherit' }}>
                    {
                        tabSection.tabs.map((subtab) => (
                          <Button
                            requiresRounded
                            requiresBackground
                            iconAlt={subtab.tabName}
                            key={subtab.tabId}
                            icon={[subtab.icon, subtab.boldIcon]}
                            text={t(`links.${subtab.tabName}`)}
                            // eslint-disable-next-line max-len
                            isSmallScreen // this property helps us to indicate if the component is being used for a navbar or the sidebar
                            buttonType="tabRedirect"
                            route={subtab.tabName}
                          />
                        ))
                    }
                  </section>
                </div>
              ) : ''
            ))
        }
    </div>
    )
  )
}

export default Navbar
