/* eslint-disable no-nested-ternary */
import React, {
  useState, useRef, useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import Button from '../button/Button'
import avatarIcon from '../../../../assets/images/profile.svg'
import tv from '../../../../assets/images/tv-white.svg'
import './dropdown.scss'

const Dropdown = ({
  title, isForSettings,
  t, i18n, languagesAvailable, selectedLanguage,
  handleSelectedLanguage,
}) => {
  const navigate = useNavigate()
  const [open, setIsOpen] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const dropdownRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    if (open && !isSmallScreen) {
      document.addEventListener('click', handleClickOutside, true)
    }
    return () => document.removeEventListener('click', handleClickOutside, true)
  }, [open, isSmallScreen])

  // Checking if it is a small screen
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.availWidth < 1080) {
        setIsSmallScreen(true)
        setIsOpen(true)
      } else {
        setIsSmallScreen(false)
        setIsOpen(false)
      }
    }

    handleResize() // Change screen size on first render
    window.addEventListener('resize', handleResize) // Change ia on screen change
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (isSmallScreen && !open) {
      setIsOpen(true)
    }
  }, [])

  const handleLanguageChange = (id) => {
    if (!isSmallScreen) setIsOpen(false)
    handleSelectedLanguage(id)
  }
  useEffect(() => {
    i18n.changeLanguage(languagesAvailable[selectedLanguage]?.languageCode)
  }, [selectedLanguage])

  return (isSmallScreen
    && (
    <ul ref={dropdownRef} className={`navbarButton ${isSmallScreen ? 'tabs-section' : 'dropdown'} ${open ? 'open-dropdown' : ''}`}>
      {
          isSmallScreen
            ? <span className="title-section">{isForSettings ? t('links.Account') : t('links.Languages')}</span>
            : (
              <Button
                text={languagesAvailable[selectedLanguage]?.name || title}
                buttonType={(!isForSettings) ? 'language' : 'settings'}
                icon={[languagesAvailable[selectedLanguage]?.flagIcon || avatarIcon]}
                onClick={() => setIsOpen(!open)}
              />
            )
        }
      {
            open
              ? (
                <li className="dropdown-elements">
                  {
                    isForSettings ? (
                      <Button buttonType="" onClick={() => navigate('/tv')} text={t('links.ActivateTV')} icon={[tv]} />
                    ) : (
                      <>
                        {
                          languagesAvailable.map((value, index) => (
                            <Button
                              key={value.languageID}
                              classButton="language_options"
                              onClick={() => handleLanguageChange(index)}
                              text={t(value.name)}
                              icon={[value.flagIcon]}
                            />
                          ))
                        }
                      </>
                    )
                  }
                </li>
              ) : ''
        }
    </ul>
    )
  )
}

Dropdown.propTypes = {
  title: PropTypes.string,
  isForSettings: PropTypes.bool,
  t: PropTypes.func,
  i18n: PropTypes.shape({
    language: PropTypes.string,
    changeLanguage: PropTypes.func,
  }),
  languagesAvailable: PropTypes.arrayOf(PropTypes.shape({
    languageID: PropTypes.string,
    languageCode: PropTypes.string,
    name: PropTypes.string,
    flagIcon: PropTypes.string,
  })),
  selectedLanguage: PropTypes.number,
  handleSelectedLanguage: PropTypes.func,
}

Dropdown.defaultProps = {
  title: undefined,
  isForSettings: false, // IF TRUE THEN IT IS AN ACCOUNT DROPDOWN ELSE IT IS A LANGUAGE SELECTOR
  t: undefined,
  i18n: undefined,
  languagesAvailable: undefined,
  selectedLanguage: 0,
  handleSelectedLanguage: () => {},
}

export default Dropdown
