/* eslint-disable no-param-reassign */
import React from 'react'
import PropTypes from 'prop-types'
import './channel.scss'

const Channel = ({
  channelInfo, channelHeight, paddingSize,
}) => (
  <div
    className="channel-container"
    style={{
      height: (channelHeight - paddingSize * 2) - 8,
      width: (channelHeight - paddingSize * 2) - 8,
      padding: paddingSize,
    }}
  >
    <img
      className="channel"
      src={`${channelInfo.imageSrcLogo['1x1']}`}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null // prevents looping
        currentTarget.src = `https://ui-avatars.com/api/?name=${channelInfo.name}`
      }}
      alt="channel-icon"
    />
  </div>
)

Channel.propTypes = {
  channelInfo: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    imageSrcLogo: PropTypes.shape({
      '1x1': PropTypes.string,
      type: PropTypes.string,
    }),
  }).isRequired,
  channelHeight: PropTypes.number,
  paddingSize: PropTypes.number,
}

Channel.defaultProps = {
  channelHeight: 0,
  paddingSize: 0,
}

/**
 * Checks if the program needs a render
 * @param {Object} oldState previous props of component
 * @param {Object} newState new props of component
 * @returns (
 *  true -> doesn't renders component
 *  false -> renders the component
 * )
 */
const checkCanRender = (oldState, newState) => oldState.channelHeight === newState.channelHeight

const MemoChannel = React.memo(Channel, checkCanRender)

export default MemoChannel
