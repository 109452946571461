var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { checkIfIsMidnight, checkSameDate, getMidnight } from '../../utils/dateUtils';
import { channelAdaptor } from './adaptors/Adaptors';
/**
 * Get selected date EPG
 * @param date -> date to fetch
 * @returns cleanned EPG
 */
export var fetchEPG = function (date) { return __awaiter(void 0, void 0, void 0, function () {
    var month, day, getActualDate, _a, statusCode, EPG, completeEPG, midnight_1, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 4]);
                month = (date.getMonth() + 1) < 10 ? "0".concat(date.getMonth() + 1) : date.getMonth() + 1;
                day = (date.getDate()) < 10 ? "0".concat(date.getDate()) : date.getDate();
                getActualDate = date.getFullYear() + '-' + month + '-' + day;
                return [4 /*yield*/, fetch("https://wot-schedules-prod.s3.amazonaws.com/acts29/epg_".concat(getActualDate, "_").concat(localStorage.getItem('i18nextLng'), ".json"), {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(function (res) { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _a = [res.status];
                                return [4 /*yield*/, res.json()];
                            case 1: return [2 /*return*/, _a.concat([_b.sent()])];
                        }
                    }); }); })];
            case 1:
                _a = _b.sent(), statusCode = _a[0], EPG = _a[1];
                return [4 /*yield*/, EPG.map(function (channel) {
                        return channelAdaptor(channel);
                    })];
            case 2:
                completeEPG = _b.sent();
                midnight_1 = getMidnight(date);
                completeEPG.map(function (channel) {
                    channel.programs.map(function (program, index) {
                        if (program.end > program.start
                            && !checkSameDate(program.end, program.start)
                            && checkSameDate(midnight_1, program.end)
                            && !checkIfIsMidnight(program.end)) {
                            channel.programs = channel.programs.splice(index, channel.programs.length - 1);
                        }
                        if (checkSameDate(midnight_1, program.start)
                            && checkIfIsMidnight(program.start)) {
                            channel.programs = channel.programs.splice(index, channel.programs.length - 1);
                        }
                    });
                });
                return [2 /*return*/, [statusCode, completeEPG]];
            case 3:
                error_1 = _b.sent();
                return [2 /*return*/, ("Something went wrong. Error: ".concat(error_1))];
            case 4: return [2 /*return*/];
        }
    });
}); };
