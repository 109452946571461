/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import './button.scss'

const Button = ({
  iconAlt, icon, text, route, buttonType, onClick, classButton, iconClass, title, closeFunc, isSmallScreen, requiresBackground, requiresRounded,
}) => {
  const [className, setClassName] = useState('')
  const location = useLocation()
  const iconType = location.pathname.toLowerCase().includes(route?.toLowerCase()) ? icon[1] : icon[0]
  const navigate = useNavigate()

  const buttonBehaviour = () => {
    if (buttonType === 'logo') {
      navigate('/')
    } else if (onClick) {
      onClick()
    } else if (closeFunc) {
      navigate(`/${route.toLowerCase().replace(/\s/g, '')}`)
      closeFunc()
    } else {
      navigate(`/${route.toLowerCase().replace(/\s/g, '')}`)
    }
  }

  useEffect(() => {
    if (location.pathname.toLowerCase().includes(route?.toLowerCase())) {
      const status = (isSmallScreen && requiresBackground) ? 'active responsivebutton selected' : (buttonType === 'language' && requiresBackground) ? 'active sel' : (requiresBackground) ? 'active' : ''
      const rounded = (requiresRounded) ? 'rounded' : ''
      setClassName(`${status} ${rounded} `) // AS A RESPONSIVE NAVBAR
    } else {
      const status = (isSmallScreen && requiresBackground) ? 'responsivebutton' : (buttonType === 'language' && requiresBackground) ? 'language' : ''
      setClassName(`${status}`) // AS A SIDE BAR
    }
  }, [location.pathname])

  return (
    <button title={title} type="button" className={classButton || className} onClick={() => buttonBehaviour()}>
      { icon.length > 0 ? <img className={iconClass} alt={iconAlt} src={iconType} /> : '' }
      { text === 'avatars' || !text ? '' : <span className={(isSmallScreen) ? 'responsive-text' : ''}>{text}</span> }
    </button>
  )
}

Button.propTypes = {
  iconAlt: PropTypes.string,
  icon: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string,
  buttonType: PropTypes.string,
  onClick: PropTypes.func,
  classButton: PropTypes.string,
  iconClass: PropTypes.string,
  title: PropTypes.string,
  closeFunc: PropTypes.func,
  isSmallScreen: PropTypes.bool,
  route: PropTypes.string,
  requiresBackground: PropTypes.bool,
  requiresRounded: PropTypes.bool,
}

Button.defaultProps = {
  iconAlt: undefined,
  icon: [],
  text: undefined,
  buttonType: undefined,
  onClick: undefined,
  classButton: undefined,
  iconClass: '',
  title: undefined,
  closeFunc: undefined,
  isSmallScreen: false,
  route: undefined,
  requiresBackground: false,
  requiresRounded: false,
}

export default Button
