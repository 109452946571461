/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import './program.scss'
import { returnProgramContext } from '../../ProgramContext'
import { getCurrentDateTime, getMidnight } from '../../../utils/dateUtils'

// Function to substract the dates
const substractDates = (endTime, startTime) => Math.abs(endTime - startTime)

const borderInPixels = 8
/**
 * @param {Program.program} program
 * @param {Program.actualProgram} actualProgram
 * @returns Array of [minuts, realPercentage]
 */
const getProgramTime = (program, actualProgram, date) => {
  let minutes

  if (program.start.getDate() === date.getDate() - 1) {
    minutes = substractDates(program.end, getMidnight(date)) / 60000
  } else {
    minutes = substractDates(program.end, program.start) / 60000
  }

  const realPercentage = actualProgram.isActualProgram
    ? (minutes - actualProgram.actualPercentage) : 0

  return [minutes, realPercentage]
}

const Program = ({
  program, actualProgram, upNextProgram, selectedDate,
  channelHeight, minuteInPixels, isFirst,
}) => {
  const [miliseconds, setMiliseconds] = useState(0)
  const [isSelected, setIsSelected] = useState(false)
  const {
    fetchCurrentProgram,
    fetchInformationProgram,
    programInPlay,
    setShowProgramInformation,
  } = returnProgramContext()
  const programRef = useRef(null)
  const location = useLocation()

  useEffect(() => {
    setMiliseconds(substractDates(program.end, program.start))
  }, [])

  useEffect(async () => {
    // If the program is available to play
    if (actualProgram.isActualProgram && !location.pathname.includes('schedule')) {
      // If the program is on the same channel as the user or there is no channel (first-render)
      if (program.channel === programInPlay?.channel || isFirst) {
        // Fetch the video
        const currentMinute = getProgramTime(program, actualProgram, selectedDate)
        await fetchCurrentProgram({ ...program, currentMinute: currentMinute[1] }, upNextProgram)
      }
    }
  }, [actualProgram.isActualProgram])

  // Function to get program width
  const getProgramLength = () => {
    const [minutes, realPercentage] = getProgramTime(
      program,
      actualProgram,
      selectedDate,
    )
    const gradientToPaint = Math.ceil(realPercentage * minuteInPixels)

    return {
      width: `${minutes * minuteInPixels - borderInPixels}px`,
      maxWidth: `${minutes * minuteInPixels - borderInPixels}px`,
      borderColor: isSelected ? 'white' : 'black',
      background: actualProgram.isActualProgram && realPercentage > 0 ? `linear-gradient(90deg, #00335A 0%, #00335A ${gradientToPaint}px, #151515 ${gradientToPaint}px)` : '#151515',
      height: channelHeight - borderInPixels,
      maxHeight: channelHeight,
    }
  }
  // Close program information when user clicks outside
  const handleClickOutside = (event) => {
    if (programRef && !programRef.current.contains(event.target)) {
      setIsSelected(false)
      setShowProgramInformation(false)

      document.removeEventListener('click', handleClickOutside, true)
    }
  }
  // Close program information when user scrolls
  const handleScroll = () => {
    if (window.scrollY) {
      setIsSelected(false)
      setShowProgramInformation(false)

      window.removeEventListener('scroll', handleScroll)
      document.removeEventListener('click', handleClickOutside, false)
    }
  }

  // Change video player and select card
  const selectProgram = () => {
    setIsSelected(true)
    // Allow play only if is actual program playing
    if (actualProgram.isActualProgram) {
      // Fetch the video
      const currentMinute = getProgramTime(program, actualProgram, selectedDate)
      fetchCurrentProgram({ ...program, currentMinute: currentMinute[1] }, upNextProgram)

      document.addEventListener('click', handleClickOutside, true)
    } else {
      window.addEventListener('scroll', handleScroll)
      document.addEventListener('click', handleClickOutside, false)

      // Fetch the program information
      fetchInformationProgram(program)
    }
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
      program.name === ''
        ? <div className="program" ref={programRef} style={getProgramLength(miliseconds)} />
        : (
          <div className="program" ref={programRef} style={getProgramLength(miliseconds)} onClick={() => selectProgram()}>
            {programInPlay?.id === program.id ? <div className="current" /> : ''}
            <h1>{program.name}</h1>
            <h2>
              {`${program.start?.getHours()}:${program.start?.getMinutes() > 0 ? program.start?.getMinutes() : '00'} 
              - ${program.end?.getHours()}:${program.end?.getMinutes() > 0 ? program.end?.getMinutes() : '00'}`}
            </h2>
          </div>
        )
      }
    </>
  )
}

Program.propTypes = {
  program: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    channel: PropTypes.string.isRequired,
  }),
  actualProgram: PropTypes.shape({
    isActualProgram: PropTypes.bool,
    actualPercentage: PropTypes.number,
  }),
  upNextProgram: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
  }),
  selectedDate: PropTypes.instanceOf(Date),
  minuteInPixels: PropTypes.number,
  channelHeight: PropTypes.number,
  isFirst: PropTypes.bool,
}

Program.defaultProps = {
  program: {
    name: '',
    id: '',
    start: getCurrentDateTime(),
    end: getCurrentDateTime(),
    channel: '',
  },
  actualProgram: {
    isActualProgram: false,
    actualPercentage: 0,
  },
  upNextProgram: {
    name: '',
    id: 0,
    start: '00:00:00',
    end: '00:00:00',
  },
  selectedDate: getCurrentDateTime(),
  minuteInPixels: 0,
  channelHeight: 0,
  isFirst: false,
}

/**
 * Checks if the program needs a render
 * @param {Object} oldState previous props of component
 * @param {Object} newState new props of component
 * @returns (
 *  true -> doesn't renders component
 *  false -> renders the component
 * )
 */
const checkCanRender = (oldState, newState) => {
  // Render if heights are different
  if (oldState.channelHeight !== newState.channelHeight) {
    return false
  }
  // Render if language is different
  if (oldState.program.name !== newState.program.name) {
    return false
  }

  const wasActive = oldState && oldState.actualProgram
  && !oldState.actualProgram.isActualProgram
  const isActive = newState && newState.actualProgram
  && !newState.actualProgram.isActualProgram

  // Change of state active
  if (wasActive !== isActive) {
    return false
  }
  if ((oldState.program.name !== newState.program.name)
  || (oldState.program.description !== newState.program.description)) return false
  // Condition for the only active programs
  if (!isActive) {
    const [, oldPercentage] = getProgramTime(
      oldState.program,
      oldState.actualProgram,
      oldState.selectedDate,
    )
    const [, newPercentage] = getProgramTime(
      newState.program,
      newState.actualProgram,
      newState.selectedDate,
    )
    return oldPercentage === newPercentage
  }
  return isActive
}

const MemoProgram = React.memo(Program, checkCanRender)

export default MemoProgram
