/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import SmallScreenNavBar from './components/extraComponents/SmallScreenNav/SmallScreenNavbar'
import Navbar from './components/extraComponents/navbar/Navbar'
import LiveHome from './components/homeRelated/home/LiveHome'
import ScheduleScreen from './components/schedule/ScheduleScreen'
import { userActions } from './store/user/user-slice'
import './theme.scss'
import SplashScreen from './components/extraComponents/loader/SplashScreen'
import LoaderScreen from './components/extraComponents/loader/LoaderScreen'
import usePageTracking from './utils/usePageTracking'
import { getUserRegion } from './services/account/region.service.tsx'
import languagesJson from '../assets/json/languages.json'

const App = () => {
  const { t, i18n } = useTranslation('common')
  const [showSplash, setShowSplash] = useState(true)
  const [showSpinner, setShowSpinner] = useState(true)
  const [regionFetched, setRegionFetched] = useState(false)
  const [languagesAvailable] = useState(languagesJson)
  const [selectedLanguage, setSelectedLanguage] = useState(0)
  const handleSpinner = (status) => setShowSpinner(status)
  const [isSkeletonActive, setIsSkeletonActive] = useState(false)
  const handleSkeleton = (state) => setIsSkeletonActive(state)
  const dispatch = useDispatch()

  const handleSelectedLanguage = (value) => setSelectedLanguage(value)
  useEffect(async () => {
    dispatch(userActions.setShowNavbar(true))
    const [statusCode, response] = await getUserRegion()
    if (statusCode === 200) {
      localStorage.setItem('regionID', response.regionID)
      setShowSpinner(false)
      setRegionFetched(true)
    }
    setTimeout(() => {
      setShowSplash(false)
    }, 3000)
  }, [])

  return (
    <>
      { usePageTracking() }
      { showSplash ? <SplashScreen /> : ''}
      { showSpinner ? <LoaderScreen /> : ''}
      <Navbar
        languagesAvailable={languagesAvailable}
        selectedLanguage={selectedLanguage}
        handleSelectedLanguage={handleSelectedLanguage}
        t={t}
        i18n={i18n}
      />
      <Routes>
        {
            (regionFetched) && (
            <>
              <Route
                path="/home"
                element={(
                  <LiveHome
                    isSkeletonActive={isSkeletonActive}
                    handleSkeleton={handleSkeleton}
                    handleSpinner={handleSpinner}
                  />
                )}
              />
              <Route
                path="/schedule"
                element={(
                  <ScheduleScreen
                    isSkeletonActive={isSkeletonActive}
                    handleSkeleton={handleSkeleton}
                    handleSpinner={handleSpinner}
                  />
                )}
              />
              <Route path="*" element={<Navigate to="/home" />} />
            </>
            )
          }
      </Routes>
      <SmallScreenNavBar t={t} />
    </>
  )
}

export default App
