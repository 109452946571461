/* eslint-disable no-nested-ternary */
import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import './programInformation.scss'
import { returnProgramContext } from '../../ProgramContext'

import Button from '../../extraComponents/button/Button'
import cancelButton from '../../../../assets/images/cancel.svg'

const ProgramInformation = ({ isModeSchedule }) => {
  const {
    programInfo, showProgramInformation, programInPlay,
  } = returnProgramContext()
  const { t } = useTranslation('common')

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      { showProgramInformation && !isModeSchedule ? (
        <div className="program-information">
          <div className="program-information-inside">
            <img src={programInfo.imageSrcThumbnail['16x9']} alt={programInfo.name} />
            <div className="program-infomration-items">
              <div className="program-infomration-items-header">
                <h1>{programInfo.name}</h1>
              </div>
              <div className="items-body">
                <h3>{programInfo.categories.join(' • ')}</h3>
              </div>

              <p>{programInfo.description}</p>
            </div>
            <Button classButton="exit-button" icon={[cancelButton]} iconAlt="close" />
          </div>
        </div>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          { showProgramInformation && isModeSchedule ? (
            <div className="program-information2">
              <div className="program-information-inside">
                <div className="program-infomration-items">
                  <div className="program-information-row">
                    <div className="program-infomration-items-header2">
                      <h1>{programInfo.name}</h1>
                      <h3>{programInfo.categories.join(' • ')}</h3>
                    </div>
                    {programInfo.id === programInPlay.id
                      ? (
                        <span className="live">
                          {t('common.LIVE')}
                        </span>
                      )
                      : ''}
                  </div>
                  <p>{programInfo.description}</p>
                </div>
                <img src={programInfo.imageSrcThumbnail['16x9']} alt={programInfo.name} />
                <Button classButton="exit-button" icon={[cancelButton]} iconAlt="close" />
              </div>
            </div>
          ) : ''}

        </>
      )}
    </>

  )
}

ProgramInformation.propTypes = {
  isModeSchedule: PropTypes.bool,
}

ProgramInformation.defaultProps = {
  isModeSchedule: false,
}

export default ProgramInformation
